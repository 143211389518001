.content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.08));
	background: #ffffff;
	padding: 0px 100px;
	gap: 331px;
}

.breadcrumb {
	font-size: var(--txt-size-XSmall);
	display: flex;
	gap: 10px;
	align-items: center;
	position: relative;
	height: 50px;
	flex-wrap: wrap;
}

.breadcrumbItemContainer {
	display: flex;
	align-items: center;
	gap: 10px;
}

.breadcrumbItem {
	width: max-content;
}

.breadcrumbItem > p {
	font-weight: 500;
	color: #333333;
	font-size: 14px;
	line-height: 18px;
}
.breadcrumbItem > a {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #293990;
}


@media screen and (min-width: 426px) and (max-width: 1080px) {
	.content {
		height: auto;
		padding: 20px;
		align-items: flex-start;
	}
	.breadcrumb {
		align-items: flex-start;
		display: flex;
		height: auto;
	}
}

@media screen and (max-width: 425px) {
	.content {
		height: auto;
		padding: 20px;
		align-items: flex-start;
	}
}
