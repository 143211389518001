.container {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 100px;
	background: var(--color-9-grey);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 100px;
	gap: 20px;
}

.logo {
	max-width: 302.44px;
	width: 100%;
	cursor: pointer;
}

.content {
	position: relative;
}

.contentButtons {
	display: flex;
	gap: 12px;
}

.buttonsClose {
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 20px;
}
.buttonClose {
	width: 211px;
}

.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 11px 16px;
	gap: 8px;
	height: 46px;
	background: var(--color-primary-blue);
	border-radius: 23px;
	border: unset;
	color: white;
	font-style: normal;
	font-weight: 400;
	font-size: var(--txt-size-Small);
	line-height: 20px;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none;
}

.buttonActive {
	background: var(--color-light-blue);
}

.button span {
	font-weight: 500;
}
.buttons {
	display: flex;
	justify-content: center;
	gap: 24px;
	width: 100%;
}
.button1 {
	width: 211px;
}
.button2 {
	width: 211px;
}
.modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 16px;
	background: var(--color-9-grey);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	width: 100%;
	min-width: 340px;
	z-index: 2;
	top: 7px;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.4s linear;
}

.active {
	visibility: visible;
	opacity: 1;
}

.userName {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 11px;
}

.userName p {
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: var(--color-0-grey);
	text-transform: capitalize;
}

.infoContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
}

.item p:nth-child(1) {
	font-weight: 400;
	font-size: var(--txt-size-XSmall);
	line-height: 18px;
	color: var(--color-2-grey);
}
.item p:nth-child(2) {
	font-weight: 400;
	font-size: var(--txt-size-Default);
	line-height: 24px;
	color: var(--color-1-grey);
	text-transform: capitalize;
}

.line {
	width: 100%;
	height: 1px;
	background: var(--color-3-grey);
}

.links {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.link {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;
	font-style: normal;
	font-weight: 500;
	font-size: var(--txt-size-Default);
	color: var(--color-primary-blue);
	text-decoration: none;
	background: unset;
	border: unset;
	cursor: pointer;
}

/*.link:hover,
.link:focus,
.link:active {
	color: var(--color-light-blue);
	fill: var(--color-light-blue);
}*/

.logout {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 4px;
	cursor: pointer;
}

.logout {
	background: none;
	border: none;
}
.logout p {
	font-style: normal;
	font-weight: 400;
	font-size: var(--txt-size-Default);
	line-height: 24px;
	color: var(--color-primary-blue);
}

/* responsive mobile */
@media (max-width: 900px) {
	.container {
		padding: 0px 16px;
	}

	.logo {
		max-width: 173.56px;
	}

	.description {
		display: none;
	}

	.button {
		padding: 11px 11px;
	}
	.modal {
		min-width: 300px;
		right: 0;
	}
}

.mobileButton {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: var(--color-primary-blue);
	width: 46px;
	height: 46px;
	border-radius: 50%;
	border: unset;
}

/* responsive tablet */
@media (min-width: 900px) and (max-width: 961px) {
	.container {
		padding: 0px 40px;
	}
}

@media screen and (max-width: 500px) {
	.buttonsClose {
		display: flex;
		flex-direction: column;
	}
	.buttonClose {
		width: 100%;
	}
	.text {
		font-size: 16px !important;
	}
	.buttons {
		flex-direction: column;
		align-items: center;
	}
	.button1 {
		width: 100%;
	}
	.button2 {
		width: 100%;
	}
}

@media (min-width: 900px) {
	.mobileButton {
		display: none;
	}
}
