.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.empty {
    background: var(--color-6-grey);
    color: var(--color-2-grey);
}

.notEmpty {
    background: var(--color-9-grey);
    color: var(--color-1-grey);
}

.contentSelect {
    position: relative;
    width: 100%;
}

.select {
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    padding: 12px 32px 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    height: 48px;
    cursor: pointer;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

.select:focus {
    color: #333333;
    background: #ffffff;
    border: 1px solid var(--color-primary-blue);
}

.options {
    position: absolute;
    background: #fbfbfb;
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    width: 100%;
    z-index: 10;
    overflow: auto;
    max-height: 200px;
}

.option {
    padding: 16px;
    gap: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    border: unset;
    background: transparent;
    text-align: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

.option:hover {
    color: #ffffff;
    background: #293990;
    cursor: pointer;
}

.icon {
    position: absolute;
    right: 12px;
    top: 12px;
}

@media (max-width: 641px) {
    .label {
        font-size: 16px;
        line-height: 20px;
    }
    .select {
        height: 40px;
        font-size: 14px;
        line-height: 18px;
    }

    .option {
        font-size: 14px;
        padding: 12px 16px;
    }
    .icon {
        position: absolute;
        right: 8px;
        top: 8px;
    }
}
