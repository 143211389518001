.mainContainer {
	display: flex;
	flex-direction: column !important;
	gap: 40px;
	padding: 7vh 0vh;
}
.containerImgs {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.containerImgs:hover{
	cursor: pointer;
}
.imgLogoInfonavit {
	width: 250px;
	height: auto;
}
.mainContainerForm {
	display: flex;
	justify-content: center;
	width: 100%;
	height: auto;
}
.containerForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 32px 24px;
	border-radius: 8px !important;
	width: 815px;
	height: auto;
	gap: 24px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15) !important;
}
@media (max-width: 1200px) {
	.containerForm {
		width: 600px;
	}
	.imgLogoInfonavit {
		width: 40%;
		height: auto;
	}
}
@media (max-width: 600px) {
	.containerForm {
		width: 95%;
	}
	.imgLogoInfonavit {
		width: 80%;
		height: auto;
	}
}
