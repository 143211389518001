.content {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.container {
	display: flex;
	justify-content: space-between;
	background-color: #333333;
	padding: 24px 100px;
	color: #ffffff;
}

.subContainer30 {
	min-width: 388px;
}
.socialMediaList {
	display: flex;
	align-items: center;
	gap: 24px;
}
.containerLabelTitle {
	margin-bottom: 8px;
}
.labelTitle {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}
.containerLabelPhone {
	display: flex;
	gap: 40px;
}
.subContainerLabelPhone {
	display: flex;
	flex-direction: column;
}
.labelPhone {
	font-size: 30px;
	font-weight: 400;
	line-height: 34px;
}
.labelCity {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}
.containerSubFooter {
	display: flex;
	align-items: center;
	height: 70px;
	width: 100%;
	background-color: black;
	border-top-width: 1px;
	border-top-color: #ffffff;
	border-top-style: solid;
	padding: 24px 100px;
	box-sizing: border-box;
}
.labelCopyright {
	color: #ffffff;
	font-weight: 500;
	margin-right: 80px;
	font-size: 14px;
	line-height: 18px;
}
.links {
	color: #ffffff !important;
	text-decoration: underline;
	margin-right: 80px !important;
	cursor: pointer;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}
@media (max-width: 1024px) {
	.labelCopyright {
		margin-right: 0px;
	}
	.links {
		margin-right: 0px !important;
	}
	.socialMediaList {
		display: flex;
		gap: 24px;
		justify-content: center;
	}
	.containerSubFooter {
		justify-content: space-around;
	}
}
@media (max-width: 950px) {

	.container {
		flex-direction: column;
		padding: 24px 16px;
		gap: 40px;
	}
	.containerLabelPhone {
		flex-direction: column;
		gap: 16px;
	}
	.subContainer70 {
		width: 100%;
	}
	.subContainer30 {
		width: 100%;
		min-width: unset;
	}
	.subContainerLabelPhone {
		width: 100%;
	}

	.containerSubFooter {
		padding: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		padding: 24px 16px;
	}
	.labelCopyright {
		margin: 0px;
		margin-top: 10px;
	}
	.links {
		margin: 10px 0px 0px 0px !important;
	}
	.socialMediaList {
		display: flex;
		gap: 24px;
		justify-content: flex-start;
	}
}
