@import '../../config/colors.css';
@import '../../config/fontSize.css';

.subContainer {
	width: 100%;
	height: auto;
	background-image: url('../../../src/assets/imgs/backgroundHero-login.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	justify-content: center;
}
.textLoading {
	color: rgba(51, 51, 51, 1);
	font-weight: 400;
	font-size: 18px;
	margin: 0px;
}
.containerCaptcha {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}
.labelContainerInput > label {
	text-align: center;
	font-size: 13px;
}
.containerButtonsForm,
.containerButton {
	width: 520px;
	display: flex;
	justify-content: center;
	gap: 20px;
}
.label {
	width: 100%;
}
.infoLabel {
	color: var(--color-2-grey);
	width: 100%;
	text-align: left;
}
@media (max-width: 1200px) {
	.containerCaptcha {
		width: 50%;
	}
}
@media (max-width: 600px) {
	.containerCaptcha {
		width: 100%;
	}
	.containerButtonsForm,
	.containerButtonModal {
		width: 80%;
		flex-direction: column-reverse;
		align-items: center;
	}
	.containerButton {
		width: 70%;
	}
}
