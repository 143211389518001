#tabla {
    border-radius: 8px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
    box-shadow: unset;
}

.header {
    background: #293990;
    text-align: left;
    height: 56px;
}

.header > tr > th {
    color: white;
    padding: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

#tabla tr:nth-child(even) {
    background-color: var(--color-5-grey);
}

.par {
    background-color: var(--color-5-grey);
}

.impar {
    background-color: white;
}

.empty {
    font-size: 18px;
    text-align: center;
    background-color: white;
}

tbody tr td {
    padding: 16px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-align: left;
}

.pagination > .MuiPaginationItem-previousNext {
    background-color: #293990 !important;
}

.sticky {
    position: sticky;
    left: 0;
    z-index: 1;
}

.stickyHeader {
    position: sticky;
    left: 0;
    background: #293990;
    z-index: 1;
}

.scroll {
    filter: drop-shadow(8px 0px 8px rgba(0, 0, 0, 0.1));
}

@media (max-width: 400px) {
    .pagination {
        gap: 8px;
    }

    .stack {
        width: 100%;
    }
}