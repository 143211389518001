.filter {
    width: 100%;
    display: grid;
    gap: 16px;
}

.container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 16px;
    width: 100%;
}

.contarTwo {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 16px;
    width: 100%;
}

.col {
    grid-column: span 3;
}

.colTwo {
    grid-column: span 2;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    grid-column: span 2;
    width: 100%;
}

@media (max-width: 1200px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
    }
}
