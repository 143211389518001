@import '../../config/colors.css';
@import '../../config/fontSize.css';

.subContainer {
	width: 100%;
	background-image: url('../../../src/assets/imgs/backgroundHero-login.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	justify-content: center;
}
.successRegister {
	width: 100%;
}
.containerButton {
	width: 240px;
	margin: auto;
	margin-top: 10px;
}
.containerCaptcha {
	width: 320px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-bottom: 8px;
}
.successRegister {
	width: 100%;
}
.containerButton {
	width: 240px;
	margin: auto;
	margin-top: 10px;
}

.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}
.labelContainerInput > label {
	font-size: 13px;
}
.containerButtonsForm {
	width: 240px;
	display: flex;
	gap: 20px;
}
.label {
	width: 100%;
}
.containerButtonModal {
	width: 40%;
	align-self: center;
}

.txtIntro {
	margin: 0;
	font-family: 'Geomanist';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
}

@media (max-width: 1200px) {
	.containerButtonModal {
		width: 80%;
	}
}
@media (max-width: 600px) {
	.containerCaptcha {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-top: 4px;
		margin-bottom: 20px;
	}
	.containerButtonModal {
		width: 100%;
		flex-direction: column-reverse;
		gap: 0px;
	}
	.successRegister {
		width: 100%;
	}
	.containerButton {
		width: 100%;
	}
}
