.container {
	padding-bottom: 100px;
	padding-left: 100px;
	padding-right: 100px;
	background: #f2f2f2;
}

.header {
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 3;
}

.content {
	background: #f2f2f2;
}

@media screen and (max-width: 768px) {
	.container {
		padding-bottom: 20px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
