.label{
    color: var(--color-1-grey);
    font-size: var(--txt-size-Default);
    font-weight: 500;
    line-height: 24px;
    padding: 0;
}

.label > span {
    color: var(--color-primary-red);
}

@media (max-width:641px)  {
    .label{
        font-size: var(--txt-size-Small);
        line-height: 20px;
    }
}