.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Text-default {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: var(--color-1-grey);
}

.Text-Negrita {
	font-weight: 1000;
}

.Text-Small {
	font-size: 16px;
	line-height: 20px;
}

.Text-XSmall {
	font-size: 14px;
	line-height: 18px;
}

.Text-Medium {
	font-size: 24px;
	line-height: 32px;
}

.Text-Large {
	font-size: 28px;
	line-height: 40px;
}

.Hero-Title {
	font-style: normal;
	font-weight: 500;
	font-size: 36px;
	line-height: 36px;
}

.Hero-Description {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}

h2 {
	font-weight: 500;
	font-size: 36px;
	line-height: 60px;
	text-align: center;
	padding: 40px 0 20px 0;
}

h4 {
	font-weight: 500;
	font-size: 26px;
	line-height: 30px;
	margin: 0px 0px 16px 0px;
}

h5 {
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
}

h6 {
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
}

.h7 {
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
}

.mandatory {
	color: var(--color-primary-red);
}

.Color-2-grey {
	color: var(--color-2-grey);
}
