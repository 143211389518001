.tableInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.tableInfo p {
    padding: 10px;
    display: flex;
    font-size: 18px;
    line-height: 24px;
    gap: 4px;
}

.tableInfo span {
    font-weight: 500;
}

@media (max-width: 1200px) {
    .tableInfo {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .tableInfo {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 500px) {
    .tableInfo {
        grid-template-columns: repeat(1, 1fr);
    }
}

.skeleton {
    background-color: #e0e0e0;
    height: 24px;
    border-radius: 4px;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}
