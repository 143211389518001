.alertsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.alert{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    background-color: #EDF0FF;
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-primary-blue);
}

.txtInfo{
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;  
    color: var(--color-2-grey);
    align-self: flex-start;
}

.visorContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.buttonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.modalMessage{
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

@media screen and (max-width: 600px) {
	.buttonsContainer  {
		flex-direction: column-reverse;
		gap: 20px;
        justify-content: center;
        align-items: center;
	}
}