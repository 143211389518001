.mainContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 24px;
    text-align: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
}

.headContainer {
    display: flex;
    justify-content: space-between;
}

.headContainerAlert {
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: center;
}

.containerButtonModal {
    width: 100%;
    display: flex;
    justify-content: center;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}

.body > p {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
}

@media (max-width: 1200px) {
    .mainContainer {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .mainContainer {
        width: 80%;
    }
}
