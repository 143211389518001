.container {
	height: 40px;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding: 32px 100px 0px;
	gap: 10px;
	margin-bottom: 24px;
	background: #f2f2f2;
}
.headerAbout {
	display: flex;
	justify-content: space-between;
	gap: 40px;
}
.headerAboutContent {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	color: var(--color-primary-blue);
	font-weight: 500;
	font-size: 18px;
	text-decoration: unset;
}

@media (max-width:641px)  {
	.container {
		height: 20px;
	}
	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		padding: 16px 16px 0px;
		gap: 10px;
	}
	.headerAbout{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;
		padding: 0px;
		gap: 20px;
		font-size: 16px;
		line-height: 20px;
	}
	.headerAboutContent {
		gap: 4px;
	}
	.headerAboutContent > img {
		width: 16px;
		height: 16px;
	}
}