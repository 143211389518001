.datosContainer{
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.apellidosContainer{
	display: flex;
	flex-direction: row;
	gap: 24px;
}

@media (max-width: 600px) {
    .apellidosContainer{
		flex-direction: column;
	}
}