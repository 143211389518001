.mainContainer{
	display: flex;
	flex-direction: column;
}

.ebaParagraph{
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: var(--color-2-grey);
	align-self: stretch;
	text-align: justify;
	margin-top: 24px;
	margin-bottom: 40px;
}

.bimestre2012Txt{
	color: var(--color-primary-blue);
	font-weight: bold;
}

.hrEba{
	width: 100%;
	border: 1px solid #C6C6C6;
	margin-bottom: 40px;
}

.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}
.labelContainerInput > label {
	font-size: 13px;
}

.datosObligatoriosTxt{
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	align-self: flex-start;
	color: var(--color-1-grey);
	margin-top: 40px;
}

.containerCaptcha {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 32px;
	margin-bottom: 32px;
	align-self: center;
}

.containerButtonsForm {
    display: flex;
    flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 40px;
	width: 75%;
	align-self: center;
}

.containerButtonsForm button{
	width: 240px;
	height: 48px;
}

@media (max-width: 600px) {
    .containerCaptcha{
		width: 100%;
	}
    
    .containerButtonsForm{
		flex-direction: column-reverse;
		gap: 20px;
	}
}