.content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.content > p {
    font-size: 18px;
    line-height: 24px;
    color: #333;
    font-weight: 400;
    text-align: left;
}

.buttons {
    display: flex;
    gap: 32px;
    justify-content: center;
    width: 100%;
}

@media (max-width: 768px) {
    .buttons {
        flex-direction: column;
        gap: 16px;
    }
}