.container {
    position: relative
}

.content{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inputContent{
    position: relative;
    width: 100%;
}

.input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 12px 16px;
    gap: 10px;
    border: 1px solid var(--color-3-grey);
    border-radius: 4px;
    font-size: var(--txt-size-Default);
    font-weight: 400;
    line-height: 24px;
    height: 48px;
}

.input:focus{
    color: var(--color-1-grey);
    background: var(--color-9-grey);
    border:  1px solid var(--color-primary-blue);
}

.input:focus-visible{
    outline: none;
}

.input:disabled{
    background: var(--color-5-grey);
    opacity: 0.5;
}

.empty{
    background: var(--color-6-grey);
    color: var(--color-2-grey);
}
.notEmpty{
    background: var(--color-9-grey);
    color: var(--color-0-grey);
}


.listbox {
    width: 100%;
	margin: 0;
	padding: 0;
	z-index: 1;
	position: absolute;
	list-style: none;
	background-color: #FBFBFB;
	overflow: auto;
	max-height: 250px;
	border: 1px solid #C6C6C6;
	border-radius: 4px;
}

.listbox > li {
    padding: 12px 16px;
    color: #333333;
    font-family: 'Geomanist';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
}

.listbox > li:hover {
    background-color: #293990;
    color: white;
    cursor: pointer;
}

@media (max-width:641px)  {
    .input{
        height: 40px;
        font-size: 14px;
        line-height: 18px;
    }

    .listbox > li {
        font-size: 14px;
        padding: 12px 16px;
    }
}