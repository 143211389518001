.container{
  width: 100%;  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin-bottom: 8px;
}

.inputFileContainer {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 24px;
  padding: 0;
}

label.label input[type="file"] {
  display: none;
}

.label {
  cursor: pointer;
  margin-left: auto;
  display: inline-block;
}

.txtElegirArchivo{
  font-family: 'Geomanist';
  color: var(--color-primary-blue);
  font-size: var(--txt-size-Default);
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.txtEliminarArchivo{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--color-primary-red);
  font-size: var(--txt-size-Default);
  background: none;
  gap: 8px;
  font-weight: 400;
  line-height: 24px;
  border: unset;
  cursor: pointer;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
}

.inputName{
  font-size: var(--txt-size-Default);
  color: var(--color-1-grey);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.messageNoFile{
  max-width: 375px;
  overflow-x: hidden;
  font-family: 'Geomanist';
  color: var(--color-2-grey);
  font-size: var(--txt-size-Default);
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  max-width: 450px;
}

.messageFile{
  width: 100%;
  max-width: 375px;
  overflow-x: hidden;
  font-family: 'Geomanist';
  color: var(--color-1-grey);
  font-size: var(--txt-size-Default);
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}

.line{
  border-top: 1px solid var(--color-3-grey);
  height: 0px;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .inputFileContainer{
    display: flex;
    flex-wrap: wrap;
  }
  .inputName{
    order: 1;
  }
  .label{
    order: 2;
  }
  .messageFile{
    width: 100%;
    max-width: 600px;
    order: 3;
  }
  .messageNoFile{
    width: 100%;
    order: 3;
  }
}

@media only screen and (max-width: 600px) {
  .messageFile{
    max-width: 292px;
  }
  .inputName{
    width: auto;
  }
}