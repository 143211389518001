h4 {
    margin: 0;
}

.modalInfoContainer{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.modalInfoContainer span{
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    align-self: flex-start;
}

.nrpContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.txtInfo{
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;  
    color: var(--color-2-grey);
    align-self: flex-start;
}

.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}
.labelContainerInput > label {
	font-size: 13px;
}

.efirmaContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.buttonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    width: 75%;
    gap: 40px;
}

.buttonsContainer button{
    width: 240px;
	height: 48px;
}

@media screen and (max-width: 600px) {
	.buttonsContainer  {
		flex-direction: column-reverse;
		gap: 20px;
	}
    .buttonsContainer button{
        align-self: center;
    }
}