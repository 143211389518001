.title {
    color: var(--color-1-grey);
    font-size: var(--txt-size-XLarge);
    line-height: var(--txt-size-XXLarge);
    font-weight: 500;
    align-self: flex-start;
}

@media (max-width:641px)  {
    .title {
        line-height: var(--txt-size-XLarge);
    }
}