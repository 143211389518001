.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 100px;
    height: 200px;
    position: relative;
}

.header::after {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(41, 57, 144, 0.5), rgba(41, 57, 144, 0.5)), linear-gradient(90deg, rgba(0, 0, 0, 0.8) 2.57%, rgba(0, 0, 0, 0) 33.85%);
    top:0;
    left:0;
    bottom: 0;
    right: 0;
}

.header > h1 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: white;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .header{
        padding: 10px 10px 10px 16px;
    }
    .header > h1 {
        font-size: 26px;
        line-height: 30px;
    }
}