@import '../../config/colors.css';
.containerInput {
	display: flex;
	align-items: flex-start !important;
	margin: 0px !important;
	width: 100%;
}
.labelContainerInput {
	display: flex;
	margin: 0;
	color: var(--color-1-grey);
	font-size: 14;
	font-weight: 500;
	line-height: 24px;
}
.labelRequired {
	color: var(--color-primary-red);
}
.textField {
	width: 100%;
	background-color: var(--color-6-grey);
}
.textFieldHelperText {
	margin: 0;
	border-radius: 5;
	height: 30;
	color: var(--color-primary-blue);
	background-color: #ffe2e5;
	border-color: var(--color-primary-blue);
	border-style: solid;
	border-width: 1;
	display: flex;
	align-items: center;
	padding-left: 10;
}
