@import '../../config/colors.css';

.subContainer {
	height: auto;
	min-height: 95vh;
	background-image: url('../../../src/assets/imgs/backgroundHero-login.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
    display: flex;
    align-items: center;
	justify-content: center;
	flex-direction: column;
}

.imgLogoInfonavit {
	width: 100%;
	height: auto;
}
.textInformation {
	color: #ffffff;
	width: 90%;
	height: auto;
	font-size: 24px;
}
.containerButtons {
	width: 90%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.mainContainerForm {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.containerForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 45px;
	border-radius: 25px !important;
	height: auto;
	width: 60%;
}

.containerInput {
	display: flex;
	align-items: flex-start !important;
	margin: 0px !important;
	width: 100%;
}
.containerCheckbox {
	width: 100%;
}
.textField {
	width: 100%;
}
.containerConsultNSS {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}
.labelConsultNSS {
	color: var(--color-primary-main);
}
.labelForgotPass {
	color: #7e8094;
	text-decoration: none;
}

.containerButtonsForm {
    display: flex;
    flex-direction: row;
	justify-content: space-evenly;
	gap: 40px;
	width: 75%;
}



.buttonLogIn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 50px;
	border-width: 1px;
	border-style: solid;
	border-color: white;
	min-height: 45px;
	height: auto;
	width: 100%;
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 14px;
	background-color: var(--color-primary-main);
	cursor: pointer;
}
.buttonLogIn:hover {
	background-color: var(--color-primary-dark);
	transition: 0.5s;
}
.buttonLogIn:active {
	background-color: var(--color-primary-main);
	transition: 0.5s;
}
.dividerButtons {
	width: 100%;
	background-color: #cbcbcb;
	height: 0.5px;
	margin-bottom: 30px;
	margin-top: 30px;
}
.containerCaptcha {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 32px;
	margin-bottom: 32px;
}
.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}
.labelContainerInput > label {
	font-size: 13px;
}

.textInformative{
	color: var(--color-2-grey);
	align-self: flex-start;
	margin: 24px 0;
}

.datosObligatoriosTxt{
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	align-self: flex-start;
	color: var(--color-1-grey);
}

/* @media (max-width: 900px) {
	.subContainer {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.textInformation {
		width: 100%;
		text-align: center;
	}
	.containerButtons {
		width: 100%;
	}
	.containerForm {
		margin-top: 20px;
	}
}*/



@media (max-width: 600px) {
	.subContainer {
		overflow: auto;
		height: auto;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.containerButtons {
		width: 100%;
		flex-direction: column;
		display: none;
	}

	.containerButtonsForm{
		width: 100%;
		flex-direction: column-reverse;
	}

	.containerForm {
		padding: 20px;
		width: 300px;
	}

	.containerCaptcha{
		width: 100%;
	}

	.textInformation {
		font-size: 16px;
		width: 100%;
		text-align: center;
	}
	.containerBtnAcceptModal {
		width: 100%;
	}
	.radioButtonsContainer{
		flex-direction: column;
	}
	.radioButtonsContainer .optionContainer{
		width: auto;
		margin: 10px 0;
	}
}