.downloadButton {
    display: flex;
    align-items: flex-start;
    margin: 8px;
    gap: 10px;
    color: var(--color-primary-blue);
    text-align: start;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 24px;
}

.downloadButton span {
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.downloadButton:hover{
    color: var(--color-dark-blue);
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .downloadButton{
        width: 100%;
    }
}