.header {
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 3;
}
.title {
	padding: 0px;
}
.modalMsg {
	/* padding: 40px; */
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;
}
.container {
	padding: 50px 100px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 30px;
	width: 100%;
	margin: auto;
}
.firstContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #333333;
}
.secondContainer {
	display: flex;
	justify-content: space-between;
}
.fontCarouselTitle {
	color: #ffffff;
	font-size: 40px;
	font-weight: 500;
	margin-bottom: 18px;
}
.fontCarouselText {
	color: #ffffff;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 18px;
}
.buttonCarousel {
	width: 40%;
}

.carousel {
	width: 50%;
	color: #ffffff;
}
div .carousel ul {
	width: auto;
	margin-bottom: 60px;
	margin-left: 40px;
}

div ul li button::before {
	font-size: 20px !important;
	color: #ffffff !important;
}

:global(.slick-active button:before) {
	background-color: #ffffff;
	height: 20px !important;
	width: 25px !important;
	border-radius: 10px !important;
}

.divCarousel {
	padding: auto;
	height: 450px;
}

.divCarouselText {
	padding: 40px;

	margin-top: 150px;
}

.secondChildrenContainerTwo {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 24px;
	gap: 10px;
	width: 49%;
	height: 450px;
	box-shadow: 0px 0px 20px 0px #00000026;
}
.displaySecondChildren {
	width: 100%;
}
.question {
	width: 100%;
	font-size: 26px;

	color: #333333;
}
.secondChildren {
	height: 100%;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}
.boxs {
	background-color: #edf0ff;
	display: flex;
	border-radius: 4px;
	height: 300px;
	width: 35%;
	padding: 8px;
}
.boxs1 {
	width: 90%;
	margin: auto;
	height: 250px;
}
.text {
	font-size: 20px;
	font-weight: 700;
	color: #293990;
	text-decoration: none;
	line-height: 1.6rem;
	text-align: center;
}

.textAyuda {
	display: flex;
	justify-content: right;
	align-items: center;
}
.thirdComponent {
	display: flex;
	justify-content: space-evenly;
	box-shadow: 0px 0px 20px 0px #00000026;
	padding: 24px;
	gap: 30px;
}
.containerServices {
	display: flex;
	width: 75%;
	gap: 60px;
}

.imgServicios {
	width: 80px;
	height: 80px;
}
.serviciosTitle {
	font-size: 26px;
	font-weight: 500;
	width: 20%;
}
.serviciosText {
	width: 18%;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	color: rgba(0, 0, 0, 1);
}
.linkCarousel {
	text-decoration: none;
}
.fourthComponent {
	display: flex;
	justify-content: space-between;
}

.servicesContainer {
	position: relative;
	width: 30%;
	height: 250px;
	border-radius: 8px;
}
.link {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 50px;
	padding: 10px;
	color: none;
	text-decoration: none;
	/* gap: 10px; */
}

.servicesImg {
	position: absolute;
	width: 100%;
	height: 250px;
	border-radius: 8px;

	object-fit: cover;
}
.serviceText {
	font-size: 26px;
	font-weight: 500;
	text-align: center;
	color: #ffffff;
	margin-bottom: 10px;
}
.serviceText2 {
	font-size: 18px;
	margin-bottom: 0px;
}
@media screen and (max-width: 1300px) {
	.containerServices {
		gap: 40px;
	}
	.boxs {
		height: 350px;
	}
}

@media screen and (max-width: 1270px) {
	.secondContainer {
		flex-direction: column;
		gap: 20px;
		/* width: 90%; */
	}
	.carousel {
		width: 100%;
	}
	.divCarousel {
		height: 480px;
	}
	.divCarouselText {
		padding: 40px;
	}
	.firstChildrenContainerTwo {
		width: 100%;
	}

	.secondChildrenContainerTwo {
		width: 100%;
		margin-top: 10px;
	}

	.text {
		/* margin: auto; */
		font-size: 21px;
		line-height: 1.8rem;
	}
	.servicesContainer {
		width: 32%;
	}
	.link {
		padding: 8px;
		/* gap: 10px; */
	}
	.serviceText {
		margin-bottom: 8px;
	}
	.serviceText2 {
		margin-bottom: 0px;
	}
	.serviciosText {
		width: 26%;
	}
	.containerServices {
		flex-wrap: wrap;
		gap: 20px;
	}
	.boxs {
		height: 320px;
	}
}

@media screen and (max-width: 960px) {
	.thirdComponent {
		flex-direction: column;
		gap: 10px;
	}

	.containerServices {
		width: 100%;
		gap: 15px;
	}
	.serviciosTitle {
		width: 100%;
	}
	.servicesContainer {
		width: 100%;
	}
	.fourthComponent {
		flex-direction: column;
		gap: 10px;
	}
	.link {
		padding: 10px;
	}
	.boxs1 {
		height: 100%;
		margin-top: 5px;
	}
}
@media screen and (max-width: 798px) {
	.boxs {
		height: 350px;
		width: 35%;
	}
	.boxs1 {
		height: 160px;
		margin-top: 0px;
	}
	.text {
		font-size: 18px;
	}
}
@media screen and (max-width: 700px) {
	.container {
		padding: 25px;
	}
	.secondChildrenContainerTwo {
		height: auto;
		/* padding: 5px; */
	}
	.boxImg {
		width: 150px;
	}
	.secondChildren {
		flex-direction: column;
		align-items: center;
	}
	.boxs {
		width: 90%;
		height: 120px;
	}
	.boxs1 {
		height: 80px;
	}
	.serviciosTitle {
		text-align: center;
	}

	.firstContainer {
		flex-direction: column;
	}
	.containerServices {
		justify-content: center;
	}
	.serviciosText {
		font-size: 16px;
		width: 25%;
	}
}
@media screen and (max-width: 600px) {
	.serviciosText {
		width: 40%;
	}
	.buttonCarousel {
		width: 60%;
	}
	.divCarousel {
		height: 450px;
	}
	.divCarouselText {
		margin-top: 65px;
	}
}
@media screen and (max-width: 500px) {
	.firstContainer {
		align-items: flex-start;
	}
	div .carousel ul {
		margin-bottom: 40px;
		margin-left: 40px;
	}
	.boxs {
		height: 170px;
	}
	.boxs1 {
		height: 120px;
	}
}

@media screen and (max-width: 400px) {
	.fontCarouselTitle {
		font-size: 20px;
	}
	.fontCarouselText {
		font-size: 14px;
	}
	.buttonCarousel {
		width: 100%;
	}

	.textButton {
		padding: 8px;
		font-size: 16px;
	}
	.divCarouselText {
		margin: 150px auto;
	}
	div .carousel ul {
		margin-bottom: 60px;
		margin-left: 0px;
		width: 100%;
	}
	.boxs {
		height: 180px;
	}
	.boxs1 {
		height: 140px;
	}
}
@media screen and (max-width: 350px) {
	.boxs {
		height: 180px;
	}
	.boxs1 {
		height: 160px;
	}
	.text {
		font-size: 14px;
		word-wrap: break-word;
	}
}

@media screen and (max-width: 300px) {
	.serviciosText {
		width: 50%;
		font-size: 14px;
	}
	.serviceText {
		font-size: 20px;
	}
	.serviceText2 {
		font-size: 14px;
	}
	.textButton {
		font-size: 14px;
	}
	.divCarouselText {
		margin: 100px auto;
	}
}
