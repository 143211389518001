@import '../../config/colors.css';
@import '../../config/fontSize.css';

.subContainer {
	width: 100%;
	height: auto;
	min-height: 975px;
	background-image: url('../../../src/assets/imgs/backgroundHero-login.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 30px 0px;
}
.mainContainerImgs {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.containerImgs {
	display: flex;
	flex-direction: column;
	padding-top: 272px;
	width: 65%;
	gap:24px;
}
.subContainerImgs {
	display: flex;
}
.imgLogoInfonavit {
	width: 100%;
	height: auto;
}
.textInformation {
	font-weight: 400;
	font-size: 20px;
	text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.6);
	line-height: 24px;
	width: 100%;
	color: var(--color-9-grey);
}
.containerButtons {
	width: 90%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.mainContainerForm {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.containerForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 32px;
	border-radius: 8px !important;
	min-width: 390px;
	height: auto;
	width: 350px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	gap: 24px;
}
.containerInput {
	display: flex;
	align-items: flex-start !important;
	margin: 0px !important;
	width: 100%;
}
.containerCheckbox {
	width: 100%;
}
.textField {
	width: 100%;
}
.containerConsultNSS {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.labelConsultNSS {
	color: var(--color-primary-blue);
}
.labelLink {
	color: var(--color-2-grey) !important;
	text-decoration: underline;
	text-align: center;
}
.containerButtonsForm {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.buttonLogIn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 50px;
	border-width: 1px;
	border-style: solid;
	border-color: white;
	min-height: 45px;
	height: auto;
	width: 100%;
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 14px;
	background-color: var(--color-primary-main);
	cursor: pointer;
}
.buttonLogIn:hover {
	background-color: var(--color-primary-dark);
	transition: 0.5s;
}
.buttonLogIn:active {
	background-color: var(--color-primary-blue);
	transition: 0.5s;
}
.dividerButtons {
	width: 100%;
	background-color: #cbcbcb;
	height: 0.5px;
}
.containerCaptcha {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}
.labelContainerInput > label {
	font-size: 13px;
}
.linkContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
}
.text {
	font-size: 18px;
	font-weight: 400;
	color: #333333;
}
.buttons {
	display: flex;
	justify-content: center;
	gap: 24px;
	width: 100%;
}
.button1 {
	width: 211px;
}
.button2 {
	width: 211px;
}
.buttonsClose {
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 20px;
}
.buttonClose {
	width: 211px;
}
@media (max-width: 900px) {
	.subContainer {
		height: auto;
		overflow: auto;
		padding-top: 20px;
		padding-bottom: 20px;
		gap: 24px;
	}
	.textInformation {
		width: 100%;
		text-align: center;
	}
	.containerButtons {
		width: 100%;
	}
	.containerForm {
		margin-top: 20px;
		margin: 0px 10px;
		width: 60%;
		min-width: auto;
	}
	.mainContainerForm {
		justify-content: center;
		width: auto;
	}
	.containerImgs {
		justify-content: center;
		padding-top: 40px;
	}
}
@media (max-width: 600px) {
	/* .buttons {
		gap: 10px;
	} */
	.subContainer {
		overflow: auto;
		height: auto;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.containerButtons {
		width: 100%;
		flex-direction: column;
		display: none;
	}
	.containerForm {
		padding: 20px;
		margin: 0px 10px;
		width: 100%;
		min-width: auto;
	}
	.textInformation {
		font-size: 16px;
		width: 100%;
		text-align: center;
	}
	.mainContainerForm {
		justify-content: center;
	}
}
@media (max-width: 510px) {
	.text {
		font-size: 16px !important;
	}
	.buttons {
		flex-direction: column;
		align-items: center;
	}
	.button1 {
		width: 100%;
	}
	.button2 {
		width: 100%;
	}
}
