.fielFormContainer{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.textInformative{
	margin-top: 24px;
	margin-bottom: 24px;
	color: var(--color-2-grey);
	align-self: flex-start;
}

.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}
.labelContainerInput > label {
	font-size: 13px;
}

.datosObligatoriosTxt{
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	align-self: flex-start;
	color: var(--color-1-grey);
	margin: 0;
}

.containerButtonsFormFiel {
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 40px;
	width: 75%;
	align-self: center;
}

.containerButtonsFormFiel button{
	width: 240px;
	height: 48px;
}

.containerCaptcha {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	align-self: center;
}

.hrFiel {
	width: 100%;
	border: 1px solid #C6C6C6;
	margin: 0;
}

@media (max-width: 600px) {
	.containerButtonsFormFiel{
        flex-direction: column-reverse;
		gap: 20px
    }
	.containerCaptcha{
		width: 100%;
	}
}