.alertContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: #f0f0f0; /* Color de fondo de la alerta */
    color: #333;
    border-top: 1px solid #ccc;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

/* Barra de carga que ocupa todo el ancho */
.loaderBar {
    position: relative;
    width: 100%;
    height: 4px;
    background: #d0f8ce;
    overflow: hidden;
}

.loaderBar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            90deg,
            #81c784 25%, /* Verde más claro */
            #66bb6a 50%, /* Verde principal (Material UI success) */
            #81c784 75%  /* Verde más claro */
    );
    background-size: 200% 100%;
    animation: loading 1.5s infinite linear;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.alertContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Ejemplo de botón de cierre (opcional) */
.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
    color: #999;
    transition: color 0.2s ease-in-out;
}

.closeButton:hover {
    color: #000;
}