@import '../../config/colors.css';

.container {
	border: solid 1px var(--color-grey-main);
	border-radius: 3px;
	background: white;
	width: 100%;
	margin-bottom: 5px;
}
.phraseContainer {
	font-weight: 500;
}
.inputContainer {
	background-color: #ebebeb;
	display: flex;
	justify-content: space-between;
}
.inputContainer > .inputCaptcha {
	padding-left: 6px !important;
	width: 100%;
}
.reloadButton {
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: solid 1px var(--color-grey-main);
	height: 35px;
	width: 35px;
}
.reloadButton:hover {
	background-color: var(--color-grey-main);
	cursor: pointer;
}
.textLine {
	text-decoration: line-through;
	user-select: none;
}
