.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.containerTabs {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px !important;
}

.containerSubItemTab {
    width: 100%;
    background-color: transparent;
}

.tabHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-top: 0 !important;
    margin-bottom: 10px !important;
}

/*requeridos */
.buttonTab, .buttonTabDisabled{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #FFFFFF;
    width: 100%;
    border: 0;
    cursor: pointer;
    font-family: 'Geomanist';
    font-size: 18px;
    line-height: 18px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.buttonTab{
    color: var(--color-primary-blue);
    font-weight: 500;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
}

.buttonTabDisabled {
    color: #7F7F7F;
    font-weight: 400;
    box-shadow: inset 0px -4px 8px rgba(0, 0, 0, 0.15);
}

.body{
    padding-top: 0;
    box-shadow: none;
    border: none;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    padding: 40px 25px;
    margin-top: -10px;
}

@media (max-width:641px)  {
    .body{
        padding: 24px;
    }
    /*principal activo*/
    .buttonTabDisabled,
    .buttonTab {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width:900px)  {
    .body {
        margin-bottom: 30px !important;
    }
}


.tabOrder0 {
    display: flex;
    order: 0;
    -webkit-box-ordinal-group: 0;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: 0;
    -webkit-order: 0;
}

.tabOrder1 {
    display: flex;
    order: 1;
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
}

.tabOrder2 {
    display: flex;
    order: 2;
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
}

.tabOrder3 {
    display: flex;
    order: 3;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
}

.tabOrder4 {
    display: flex;
    order: 4;
    -webkit-box-ordinal-group: 4;
    -moz-box-ordinal-group: 4;
    -ms-flex-order: 4;
    -webkit-order: 4;
}