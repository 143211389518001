:root {
	--color-primary-main: #253382;
	--color-primary-dark: #212e74;
	--color-grey-main: #cbcbcb;

	--color-primary-blue: #293990;
	--color-dark-blue: #182467;
	--color-light-blue: #4d60ca;
	--color-lighter-blue: #edf0ff;

	--color-primary-red: #dd052b; /* Falta codigo correcto */
	--color-dark-red: #b31b24;
	--color-light-red: #d26370;
	--color-lighter-red: #ffe2e5;

	--color-primary-green: #3abe21;
	--color-dark-green: #127400;
	--color-light-green: #e9fae5;

	--color-0-grey: #000;
	--color-1-grey: #333;
	--color-2-grey: #7f7f7f;
	--color-3-grey: #c6c6c6;
	--color-4-grey: #e6e6e6;
	--color-5-grey: #f2f2f2;
	--color-6-grey: #fbfbfb;
	--color-9-grey: #fff;

	--color-gray-b-light: rgb(163,174,190);
	--color-gray-b-dark: rgb(104,122,149);
}
