.content{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
}

.elementosContraseña{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
}

.elementosContraseñaTitulo{
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	color: var(--color-1-grey);
	margin: 0;
}

.elementosContraseñaItem{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 9px;
}