* {
	font-family: 'Geomanist', sans-serif;
	box-sizing: border-box;
	letter-spacing: 0.1px;
	margin: 0;
}

html {
	min-height: 100%;
	position: relative;
	background: #f2f2f2;
}
body {
	margin: 0;
	margin-bottom: 200px;
}

@media (max-width: 950px) {
	body {
		margin-bottom: 475px;
	}
}