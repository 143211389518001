.info {
	display: flex;
	flex-direction: column;
	gap: 24px;
	max-width: 812px;
	width: 100%;
}

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
}

.datos {
	font-size: var(--txt-size-Small);
	font-weight: 400;
	line-height: 20px;
	color: var(--color-1-grey);
}
.datos span {
	color: var(--color-primary-red);
}

.buttonContent {
	justify-content: center;
	display: flex;
	width: 100%;
}

.buttonContent > button {
	max-width: 226px;
}

@media (max-width: 641px) {
	.buttonContent > button {
		max-width: 100%;
	}
}
