.container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px 100px;
	gap: 32px;
	width: 100%;
	height: 58px;
	background: #f2f2f2;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 20px;
	gap: 10px;
	height: 58px;
}

.item:hover {
	background: #ffffff;
	border-bottom: 2px solid #293990;
}

.item:hover p {
	color: #293990;
}

.item p {
	margin: 0;
	font-family: 'Geomanist';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #a3aebe;
	cursor: default;
	text-align: start;
}

.submenuTramites {
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 4px;
	background: #ffffff;
	padding: 16px 0px 16px;
	background: #ffffff;
	filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
	border-radius: 0px 0px 16px 0px;
	visibility: hidden;
	transition: visibility 0s, opacity 0.5s linear;
	height: 0;
	opacity: 0;
}

.submenu {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 0px 16px;
	background: #ffffff;
	filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
	border-radius: 0px 0px 16px 0px;
	visibility: hidden;
	transition: visibility 0s, opacity 0.5s linear;
	height: 0;
	opacity: 0;
}

.subitem {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 12px 16px;
	width: 100%;
	text-decoration: none;
	font-family: 'Geomanist';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #333333;
	margin: 0;
}

.subitem:hover {
	background: #edf0ff;
	color: #293990;
}

.subTitle {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 16px;
	width: 234.5px;
	height: 32px;
	background: #f2f2f2;
	width: 100%;
	text-align: start;
}

.subTitle p {
	font-family: 'Geomanist';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #7f7f7f;
	margin: 0;
}

.subMenuContent {
	width: 100%;
}

/* responsive mobile */
@media (max-width: 641px) {
	.container {
		padding: 0px 16px;
		align-items: center;
		height: auto;
		gap: 10px;
		flex-direction: column;
	}
	.item {
		height: auto;
		text-align: center;
	}
	.submenuTramites {
		flex-direction: column;
	}
	.content {
		width: 100%;
	}

	.submenuTramites {
		position: unset;
		display: none;
		visibility: visible;
		opacity: 1;
		height: auto;
	}

	.submenu {
		position: unset;
		display: none;
		visibility: visible;
		opacity: 1;
		height: auto;
	}

	.submenuActive {
		display: block;
	}
}

/* responsive tablet */
@media (min-width: 641px) and (max-width: 961px) {
	.container {
		padding: 0px 40px;
	}
}

@media (min-width: 641px) {
	.content:hover > .submenu {
		visibility: visible;
		opacity: 1;
		height: auto;
	}

	.content:hover > .submenuTramites {
		visibility: visible;
		opacity: 1;
		height: auto;
	}
}

/* activar si se cambia al diseño mobile */
/*@media (max-width: 900px) {
	.container {
		display: none;
	}
}*/
