.alert {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    background-color: #EDF0FF;
    padding: 10px;
}

.alert span{
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-primary-blue);
}

@media only screen and (max-width: 600px) {
	.alert{
        height: auto;
        text-align: center;
    }
}