.container {
	box-sizing: border-box;
	padding: 24px;
	width: 100%;
	cursor: pointer;
	background-color: var(--color-lighter-blue);
	border-radius: 8px;
	border: 1px solid var(--color-primary-blue);
	box-shadow: unset;
}

.title {
	font-weight: 500;
	font-size: var(--txt-size-Medium);
	line-height: 20px;
	color: var(--color-1-grey);
}

.body p {
	font-weight: 400;
	font-size: var(--txt-size-Default);
	line-height: 24px;
	color: var(--color-1-grey);
}

/* responsive Mobile */
@media screen and (max-width: 641px) {
	.container {
		padding: 16px;
	}
	.title,
	.body > p {
		font-size: var(--txt-size-Small);
		line-height: 18px;
	}
}

/* responsive tablet */
@media (min-width: 641px) and (max-width: 961px) {
	.title{
		font-size: var(--txt-size-Default);
		line-height: 22px;
	}
	.body > p {
		font-size: var(--txt-size-Small);
		line-height: 20px;
	}
}

.container :global .MuiAccordionSummary-root {
	min-height: 0;
	padding: 0;
}

.container :global .MuiAccordionSummary-content {
	margin: 0;
}

.container :global .MuiAccordionDetails-root {
	padding: 8px 0 0;
}
