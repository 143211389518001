.content{
    display: flex;
    background: unset;
    border: unset;
    justify-content: flex-start;
    gap: 10px;
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    margin: 0;
    cursor: pointer;
    }

.unChecked{
    background: #E6E6E6;
    border: 1px solid #C6C6C6;
    border-radius: 2px;
    width: 18px;
    height: 18px;
}

.checked {
    background: #293990;
    border: 1px solid #C6C6C6;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}