.colorGray2{
	margin-top: 24px;
	font-size: 16px;
	line-height: 20px;
	color: var(--color-2-grey);
	text-align: justify;
}

.information{
	display: flex;
	flex-direction: column;
	width: 70%;
	margin-top: 40px;
	margin-bottom: 64px;
	align-self: center;
	gap: 48px
}

.informationRow{
	width: 100%;
	display: flex;
	flex-direction: row;
}

.nameRow{
	width: 50%;
	font-weight: 1000;
	font-size: 18px;
	line-height: 24px;
	color: var(--color-1-grey);
}

.contentRow{
	width: 50%;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: var(--color-1-grey);
}

@media screen and (max-width: 600px) {
	.information{
		width: 100%;
		gap: 24px;
	}
	.informationRow  {
		flex-direction: column;
	}
	.nameRow{
		width: 100%;
	}
	.contentRow{
		width: 100%;
	}
}