.paperContainer {
	height: auto;
	min-height: 95vh;
	background-image: url('../../../../src/assets/imgs/backgroundHero-login.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
    display: flex;
    align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}

.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.bajaTxt{
    font-family: 'Geomanist';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}