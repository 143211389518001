.content{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.textarea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 12px 16px;
    gap: 10px;
    border: 1px solid var(--color-3-grey);
    border-radius: 4px;
    font-size: var(--txt-size-Default);
    font-weight: 400;
    line-height: 24px;
}


.empty{
    background: var(--color-6-grey);
    color: var(--color-2-grey);
}

.notEmpty{
    background: var(--color-9-grey);
    color: var(--color-1-grey);
}

.textarea:focus{
    color: var(--color-1-grey);
    background: var(--color-9-grey);
    border:  1px solid var(--color-primary-blue);
}

.textarea:focus-visible{
    outline: none;
}

.textarea:disabled{
    background: var(--color-5-grey);
    opacity: 0.5;
}

@media (max-width:641px)  {
    .textarea{
        font-size: var(--txt-size-XSmall);
        line-height: 18px;
    }
}