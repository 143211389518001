.content {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.empty {
	background: #fbfbfb;
	color: #7f7f7f;
}
.notEmpty {
	background: #ffffff;
	color: #000000;
}

.inputContent {
	position: relative;
	width: 100%;
}

.input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	padding: 12px 32px 12px 16px;
	gap: 10px;
	border: 1px solid #c6c6c6;
	border-radius: 4px;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	height: 48px;
	text-align: left;
	text-overflow: ellipsis;
	cursor: pointer;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none;
}

.input:focus {
	color: #333333;
	background: #ffffff;
	border: 1px solid var(--color-primary-blue);
}

.input:focus-visible {
	outline: none;
}

.input:disabled {
	background: #f2f2f2;
	opacity: 0.5;
}

.icon {
	position: absolute;
	right: 12px;
	top: 12px;
}

@media (max-width: 641px) {
	.input {
		height: 40px;
		font-size: 14px;
		line-height: 18px;
	}

	.icon {
		position: absolute;
		right: 8px;
		top: 8px;
	}
}
