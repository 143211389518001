.content {
    position: relative;
    width: 100%;
    min-height: 60px;
    box-shadow: 0px 0px 20px 0px #00000026;
    border-radius: 8px;
}

.filterTitle {
    font-size: 18px;
    color: #293990;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    user-select: none;
    padding-top: 10px;
    transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
    justify-content: center;
    margin-top: 6px;
}

.filterTitleInside {
    font-size: 18px;
    color: #293990;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    user-select: none;
    height: 40px;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out,
    position 0.5s ease-in-out;
}

.filterContent {
    width: 100%;
}

.containerFilter {
    /*box-shadow: 0px 0px 20px 0px #00000026;*/
    /*border-radius: 8px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: relative;
    /*overflow: hidden;*/
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out,
    padding 0.3s ease-in-out, transform 0.3s ease-in-out;

    opacity: 0;
    max-height: 0;
    padding: 0;
    pointer-events: none; /* Prevent interaction when hidden */
}

.containerFilter.show {
    opacity: 1;
    max-height: 1000px; /* un valor lo suficientemente grande para mostrar el contenido */
    padding: 16px 16px 24px 16px;
    pointer-events: auto;
}

.closeFilter {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 4px;
    align-items: center;
    color: #293990;
    font-size: 14px;
    font-weight: 500;
    transition: opacity 0.3s ease-in-out;
}

.hidden {
    opacity: 0;
    max-height: 0;
    padding: 0;
    pointer-events: none;
}
