.colorGray2{
	color: var(--color-2-grey);
	margin-top: 24px;
	margin-bottom: 40px;
}

.radioButtonsContainer{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 24px;
	margin-bottom: 64px;
}

.radioLabel{
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.optionContainer{
	border: 1px solid var(--color-3-grey);
	padding: 24px 24px;
	margin: 0 12px;
	width: 347.5px;
	height: 72px;
}

.radioLabel input[type="radio"]{
	display: none;
}

.radioButtonsContainer .radioLabel span{
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 1px solid var(--color-primary-main);
	position: absolute;
	left: 0;
	top: auto;
}

.radioButtonsContainer .radioLabel span:after{
	content: "";
	height: 12px;
	width: 12px;
	background: var(--color-primary-main);
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	transform: translate(25%, 25%) scale(0);
	border-radius: 50%;
}

.radioButtonsContainer .radioLabel input[type="radio"]:checked ~ span:after{
	transform:  translate(25%, 25%)  scale(1);
}

.containerButtonsForm {
    display: flex;
    flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 40px;
	width: 75%;
}

.containerButtonsForm button{
	width: 240px;
	height: 48px;
}

@media screen and (max-width: 1200px) {
	.radioButtonsContainer {
		flex-direction: column;
		width: 100%;
	}
	.optionContainer{
		width: 100%;
	}
}
  
@media screen and (max-width: 600px) {
	.containerButtonsForm  {
		flex-direction: column-reverse;
		gap: 20px;
		width: 75%;
	}
}