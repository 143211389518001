.pagination {
	display: flex;
	padding: 0;
	gap: 16px;
	justify-content: center;
}

.pages {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.paginationItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 32px;
	padding-right: 4px;
	padding-left: 4px;
	height: 32px;
	border-radius: 4px;
	font-family: 'Geomanist';
	font-weight: 500;
	font-size: 18px;
	background: unset;
	border: unset;
	color: #7f7f7f;
}

.selected {
	color: #ffffff;
	background: #293990;
}

.previous {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0;
	gap: 8px;
}

.previousItems {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	font-family: 'Geomanist';
	font-weight: 500;
	font-size: 18px;
	background: #c6c6c6;
	border: unset;
	color: #ffffff;
}

.previous button,
.pages button {
	cursor: pointer;
}

@media (max-width: 480px) {
	.pagination {
		gap: 8px;
	}
}
@media (max-width: 400px) {
	.previousItems {
		width: 25px;
		height: 25px;
	}
	.paginationItem {
		width: 25px;
		height: 25px;
	}
}
@media (max-width: 280px) {
	.previousItems {
		width: 18px;
		height: 18px;
		font-size: 16px;
	}
	.paginationItem {
		width: 18px;
		height: 18px;
		font-size: 16px;
	}
}
