
.containerLoading {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 600px;
	height: 170px;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 8px;

}
.textLoading {
	font-size: 'Geomanist';
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	color: #333;
	margin: 0px;
}
.imgGift {
	width: 100px;
	display: block;
	margin: 0px;
	width: 50px;
	height: 50px;
}
