.subContainer {
	height: auto;
	min-height: 60vh;
	background-image: url('../../../../src/assets/imgs/backgroundHero-login.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
    display: flex;
    align-items: center;
	justify-content: center;
	flex-direction: column;
}

.mainContainerForm {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.txtIntro {
	margin: 0;
	font-family: 'Geomanist';
	font-style: normal;
	font-size: 18px;
	line-height: 24px;
    text-align: center;
}
