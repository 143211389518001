:root {
	--txt-size-XXXLarge: 38px;
	--txt-size-XXLarge: 30px;
	--txt-size-XLarge: 26px;
	--txt-size-Large: 22px;
	--txt-size-Medium: 20px;
	--txt-size-Default: 18px;
	--txt-size-Small: 16px;
	--txt-size-XSmall: 14px;
}
