.boxFirst {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 600px;
	height: auto;
	padding: 24px;
	margin: 100px auto;
	background: #ffffff;
	gap: 40px;
	text-align: center;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
	border-radius: 8px !important;
}

.containerButtonModal {
	width: 100%;
	display: flex;
	justify-content: center;
}

.body{
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
}

.body > p {
	margin: 0;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #333333;
}

@media screen and (max-width: 1150px) {
	.boxFirst {
		width: 75%;
	}
}

@media screen and (max-width: 1000px) {
	.boxFirst {
		width: 80%;
	}
}
@media screen and (max-width: 800px) {
	.boxFirst {
		width: 80%;
	}
}
@media screen and (max-width: 600px) {
	.boxFirst {
		width: 90%;
	}

	.body > p {
		font-size: 16px;
	}
}
@media screen and (max-width: 280px) {
	.boxFirst {
		padding: 10px;
	}

	.body > p {
		font-size: 16px;
	}
}
