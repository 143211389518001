.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 40px;
}

.origen {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
}

.downloadContent {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    gap: 40px;
}
