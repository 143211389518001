.alert {
	display: flex;
	box-sizing: border-box;
	align-items: flex-start;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 6px;
	height: auto;
}

.centerAlert {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
