.textInformative{
	color: var(--color-2-grey);
	align-self: flex-start;
	margin: 24px 0;
}

.datosObligatoriosTxt{
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	align-self: flex-start;
	color: var(--color-1-grey);
}

.labelContainerInput {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
}

.containerCaptcha {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 32px;
	margin-bottom: 32px;
}

.containerButtonsForm {
    display: flex;
    flex-direction: row;
	justify-content: center;
	gap: 40px;
	width: 75%;
}

.containerButtonsForm button{
	width: 240px;
	height: 48px;
}

@media only screen and (max-width: 600px) {
	.containerCaptcha{
		width: 100%;
	}
	.containerButtonsForm{
		width: 75%;
		flex-direction: column-reverse;
		gap: 20px;
	}
	.containerButtonsForm button {
		align-self: center;
	}
}